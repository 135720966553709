import styled, { css } from 'styled-components';

import { CardMainBody } from 'shared/components/Card/Card.styles';

export const SystemCardWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    max-width: 440px;
    ${CardMainBody} {
      border-radius: 24px;
      background: ${theme.colors.cardElevationHighWhite};
      width: 100%;
      height: 100%;
      max-width: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @media screen and (min-width: ${theme.breakpoints.small}) {
      width: unset;

      ${CardMainBody} {
        min-width: 308px;
        width: 308px;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      height: unset;
      width: 100%;
      ${CardMainBody} {
        width: 100%;
      }
    }
  `}
`;

export const SystemCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 4px;
  gap: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 100%;
  }
`;
