import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemCardIndividualOutput from './SystemCardIndividualOutput';
import { SystemCardOutputsWrapper } from './SystemCardOutputsSection.styles';

type SystemCardOutputsProps = {
  homeEnergy: string;
  batteryEnergy: string;
  gridEnergy: string;
  hasBatteryMonitoring: boolean;
};

export default function SystemCardOutputsSection({
  homeEnergy,
  batteryEnergy,
  gridEnergy,
  hasBatteryMonitoring,
}: SystemCardOutputsProps) {
  const { t } = useTranslation();

  const showBatteryOutput = hasBatteryMonitoring;

  return (
    <SystemCardOutputsWrapper>
      <SystemCardIndividualOutput
        header={t('systemCard.homeHeader')}
        value={homeEnergy}
        suffix={t('systemCard.kWh')}
      />
      {showBatteryOutput && (
        <SystemCardIndividualOutput
          header={t('systemCard.batteryHeader')}
          value={batteryEnergy}
          suffix={t('systemCard.kWh')}
        />
      )}
      <SystemCardIndividualOutput
        header={t('systemCard.gridHeader')}
        value={gridEnergy}
        suffix={t('systemCard.kWh')}
      />
    </SystemCardOutputsWrapper>
  );
}
