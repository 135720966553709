import React from 'react';

import { Button } from 'shared/components/Button/Button';
import { FoldersIcon } from 'shared/components/icons/FoldersIcon';
import { ArrowUpRightFromSquare } from 'shared/components/icons';
import {
  AgreementDetailsSection,
  FlexContainer,
  Label,
  SystemSection,
  Title,
  Value,
} from './AgreementsAccordion.styles';
import { Section } from './Section';

type Props = {
  overviewList: { label: string; value: string | undefined }[];
  contractDetailsList: { label: string; value: string | undefined }[];
  financialsList: { label: string; value: string | undefined }[];
  systemList: { label: string; value: string | undefined }[];
  isArchived: boolean;
  grafanaLink: string | null;
  monitoringVendorLink: string | null;
  onToggleArchive: () => void;
};
export function AgreementSection({
  overviewList,
  contractDetailsList,
  financialsList,
  systemList,
  isArchived,
  grafanaLink,
  monitoringVendorLink,
  onToggleArchive,
}: Props) {
  const handleGrafanaClick = () => {
    if (grafanaLink) {
      window.open(grafanaLink, '_blank');
    }
  };
  const handleVendorPortalClick = () => {
    if (monitoringVendorLink) {
      window.open(monitoringVendorLink, '_blank');
    }
  };

  return (
    <FlexContainer>
      <AgreementDetailsSection>
        <Section title="Overview" items={overviewList} />
        <Section title="Contract Details" items={contractDetailsList} />
        <Section title="Financials" items={financialsList} />
      </AgreementDetailsSection>

      <SystemSection>
        <Title as="h3">System</Title>
        <AgreementDetailsSection>
          {systemList.map((item) => (
            <div key={item.label}>
              <Label as="p">{item.label}</Label>
              <Value as="p">{item.value}</Value>
            </div>
          ))}
        </AgreementDetailsSection>

        <Title as="h5">Monitoring Links</Title>
        {grafanaLink && (
          <Button
            label="View in Watchmen Grafana"
            styleVariant="tertiary"
            Icon={ArrowUpRightFromSquare}
            iconRight
            onClick={handleGrafanaClick}
          />
        )}
        {monitoringVendorLink && (
          <Button
            label="View in Vendor Portal"
            styleVariant="tertiary"
            Icon={ArrowUpRightFromSquare}
            iconRight
            onClick={handleVendorPortalClick}
          />
        )}
      </SystemSection>

      <Button
        label={`${isArchived ? 'Unarchive' : 'Archive'} Agreement`}
        styleVariant="tertiary"
        Icon={FoldersIcon}
        onClick={onToggleArchive}
      />
    </FlexContainer>
  );
}

export default AgreementSection;
