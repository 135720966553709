import React, { useEffect, useState } from 'react';
import DataDogLogs from 'shared/logging/DataDogLogs';
import { useTheme } from 'styled-components';
import { LINKS } from 'shared/links';
import { useTranslation } from 'react-i18next';
import usePageName from 'hooks/usePageName';
import { FlagName } from 'api/types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import pages from 'pages';
import { errorSurflyKey, startSurflyKey } from 'constants/localStorageKeys';
import {
  CopyRightText,
  DividerStyled,
  FooterStyled,
  MenuLink,
  MenuWrapper,
  ScreenCastWrapper,
  ScreenShareWrapper,
  StoreButtons,
} from './Footer.styles';
import { AppStoreIconLarge, GooglePlayIconLarge } from '../icons';
import { ScreenCastIcon } from '../icons/ScreenCastIcon';
import { PopUpErrorModal } from '../PopUpErrorModal/PopUpErrorModal';
import { EverBrightLogo } from '../icons/EverBrightLogo';

export function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { currentPage } = usePageName();
  const {
    flags: { [FlagName.surfly]: Surfly },
  } = useFeatureFlags();
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => {
    localStorage.setItem(startSurflyKey, JSON.stringify(true));
    const newWindow = window.open(pages.SURFLY_LOADING, '_blank');
    if (!newWindow) {
      DataDogLogs.error('Footer', {
        additionalInfo: 'Surfly window is null',
      });
      setOpenModal(true);
    } else {
      newWindow.onload = () => {
        if (newWindow.opener && localStorage.getItem(startSurflyKey)) {
          setTimeout(() => {
            if (newWindow.Surfly && !newWindow.Surfly.isInsideSession) {
              newWindow.Surfly.session().startLeader();
            } else {
              DataDogLogs.error('Footer', {
                additionalInfo: 'Surfly is not initialized',
              });
              localStorage.setItem(errorSurflyKey, JSON.stringify(true));
              newWindow.location.href = `/${pages.ERROR_SURFLY}`;
            }
          }, 500);
          setTimeout(() => {
            if (!newWindow.Surfly.isInsideSession) {
              localStorage.setItem(errorSurflyKey, JSON.stringify(true));
              newWindow.location.href = `/${pages.ERROR_SURFLY}`;
            }
          }, 5000);
          if (newWindow.Surfly.isInsideSession) {
            newWindow.location.href = `/${pages.DASHBOARD}`;
          }
        }
      };
    }
  };
  useEffect(() => {
    if (window && localStorage.getItem(errorSurflyKey)) {
      if (localStorage.getItem(startSurflyKey) && window.opener) {
        const timeoutId = setTimeout(() => {
          if (window.Surfly && !window.Surfly.isInsideSession) {
            window.Surfly.session().startLeader();
          } else {
            DataDogLogs.error('Footer', {
              additionalInfo: 'Surfly is not initialized',
            });
            window.location.href = `/${pages.ERROR_SURFLY}`;
          }
        }, 500);

        const timeoutIdTwo = setTimeout(() => {
          if (!window.Surfly.isInsideSession) {
            window.location.href = `/${pages.ERROR_SURFLY}`;
          }
        }, 5000);
        if (window.Surfly.isInsideSession) {
          window.location.href = `/${pages.DASHBOARD}`;
        }
        return () => {
          clearTimeout(timeoutId);
          clearTimeout(timeoutIdTwo);
        };
      }
    }
    return undefined;
  }, []);

  const handleCloseModal = () => setOpenModal(false);
  const handleRefresh = () => window.location.reload();

  return (
    <>
      <DividerStyled />
      <FooterStyled>
        {currentPage === 'AUTH' && <EverBrightLogo />}
        <MenuWrapper>
          {LINKS.FOOTER_MENU_OPTIONS.map(({ text, link }) => (
            <MenuLink key={text} href={link} target="_blank" rel="noreferrer">
              {text}
            </MenuLink>
          ))}
        </MenuWrapper>

        {currentPage === 'SUPPORT' && Surfly ? (
          <ScreenCastWrapper onClick={handleClick}>
            <ScreenCastIcon color={theme.colors.buttonButtonPrimarySecondary2100} />
            <ScreenShareWrapper> {t('share')} </ScreenShareWrapper>
          </ScreenCastWrapper>
        ) : (
          <StoreButtons>
            <a
              href={LINKS.MOBILE_APP_STOREFRONTS.apple}
              target="_blank"
              rel="noreferrer"
              aria-label="apple store icon"
            >
              <AppStoreIconLarge color={theme.colors.iconIconSecondaryGrey90} />
            </a>
            <a
              href={LINKS.MOBILE_APP_STOREFRONTS.google}
              target="_blank"
              rel="noreferrer"
              aria-label="google play store icon"
            >
              <GooglePlayIconLarge color={theme.colors.iconIconSecondaryGrey90} />
            </a>
          </StoreButtons>
        )}
      </FooterStyled>
      <CopyRightText as="p">{t('copyright', { year: new Date().getFullYear() })}</CopyRightText>
      <PopUpErrorModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
        onRefresh={handleRefresh}
      />
    </>
  );
}

export default Footer;
