import React from 'react';
import { resolvePath } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import pages from 'pages';
import { BodyText, ChartDataErrorWrapper, HeaderText, LinkStyled } from './ChartDataError.styles';

export enum DisplayChartError {
  TRY_AGAIN = 'TRY_AGAIN',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  SITE_NOT_FOUND = 'SITE_NOT_FOUND',
}
type Props = {
  error: DisplayChartError | null;
  onClickTryAgain: () => void;
};

export function ChartDataError({ error, onClickTryAgain }: Props) {
  const { t } = useTranslation();
  return (
    <ChartDataErrorWrapper>
      <HeaderText as="h2">{t('system.monitoring.somethingWentWrong')}</HeaderText>
      <div>
        {error === DisplayChartError.SITE_NOT_FOUND ? (
          <BodyText as="p">
            <Trans
              i18nKey="system.monitoring.siteNotFound"
              components={{
                a: <LinkStyled to={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`)} />,
              }}
            />
          </BodyText>
        ) : (
          <>
            <BodyText as="p">{t('system.monitoring.unableToRetrieveData')}</BodyText>
            {error === DisplayChartError.TRY_AGAIN ? (
              <BodyText as="p">
                <Trans
                  i18nKey="system.monitoring.tryAgain"
                  components={{
                    a: <LinkStyled to="" onClick={onClickTryAgain} />,
                  }}
                />
              </BodyText>
            ) : (
              <BodyText as="p">
                <Trans
                  i18nKey="system.monitoring.contactSupport"
                  components={{
                    a: <LinkStyled to={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`)} />,
                  }}
                />
              </BodyText>
            )}
          </>
        )}
      </div>
    </ChartDataErrorWrapper>
  );
}

export default ChartDataError;
