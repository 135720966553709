import styled, { css } from 'styled-components';
import { getButtonStyleVariantValues, themeButtons } from 'shared/design-system/theme/buttons';
import { TButtonStyleVariantsKey } from 'shared/design-system/theme/buttons/TButtons';
import { FONT_FAMILY } from '../Text/Text';

export type ButtonStyledProps = {
  $margin: string;
  $isFullWidth: boolean;
  $maxWidth?: string;
  $styleVariant: TButtonStyleVariantsKey;
  $centerSvg?: boolean;
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
  ${({ $margin, $isFullWidth, $maxWidth, $styleVariant, theme, $centerSvg }) => {
    const {
      fontColor,
      fontColorHover,
      fontColorActive,
      background,
      backgroundHover,
      backgroundActive,
      border,
      borderHover,
      borderActive,
      padding,
    } = getButtonStyleVariantValues(theme, themeButtons, $styleVariant);
    let width = '100%';
    if (!$isFullWidth) {
      width = $styleVariant === 'tertiary' ? 'fit-content' : 'auto';
    }

    return css`
      display: flex;
      cursor: pointer;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      padding: ${padding};
      gap: 8px;
      color: ${fontColor};
      background: ${background};
      border: ${border};
      border-radius: 4px;
      margin: ${$margin};
      font-family: ${FONT_FAMILY};
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      height: 44px;
      width: ${width};
      min-width: ${$styleVariant === 'tertiary' ? '40px' : '100px'};
      max-width: ${$maxWidth || 'none'};
      &:hover {
        color: ${fontColorHover};
        background: ${backgroundHover};
        border: ${borderHover};
      }
      &:active {
        color: ${fontColorActive};
        background: ${backgroundActive};
        border: ${borderActive};
      }
      svg {
        transform: ${$centerSvg
          ? 'translateY(0px)'
          : 'translateY(-2px)'}; // Avenir font seems to have a weird vertical alignment
        height: auto;
      }
    `;
  }}
`;

type IconWrapperProps = {
  $iconRight?: boolean;
  $borderAlign?: boolean;
  $isOnlyIcon: boolean;
  $styleVariant: TButtonStyleVariantsKey;
};

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ $borderAlign, $isOnlyIcon, $iconRight, $styleVariant, theme }) => {
    const { fontColorHover, fontColorActive } = getButtonStyleVariantValues(
      theme,
      themeButtons,
      $styleVariant,
    );

    const iconStyle = $isOnlyIcon
      ? css`
          & svg path {
            &:hover {
              fill: ${fontColorHover};
            }
            &:active {
              fill: ${fontColorActive};
            }
          }
        `
      : '';

    return css`
      display: flex;
      align-items: center;
      margin-left: ${$borderAlign ? '-4px' : '0px'};
      order: ${$iconRight ? 1 : 0};
      ${iconStyle};
    `;
  }}
`;
