import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorModalContent } from './PopUpErrorModal.styles';
import { Modal } from '../Modal/Modal';
import { CtaButtons } from '../Modal/types';
import { ErrorLaptopImg } from '../icons';
import { Text } from '../Text/Text';

type Props = {
  openModal: boolean;
  onRefresh: () => void;
  onCloseModal: () => void;
};
export function PopUpErrorModal({ openModal, onCloseModal, onRefresh }: Props) {
  const { t } = useTranslation();
  const ctaButtons = () => {
    const buttons: CtaButtons = {
      tertiary: {
        label: t('cta.close'),
        onClick: onCloseModal,
      },
      primary: {
        label: t('error.generic.button'),
        onClick: onRefresh,
      },
    };
    return buttons;
  };
  return (
    <Modal
      contentLabel={t('error.popUps.popUp')}
      isOpen={openModal}
      subHeader
      styleVariant="tertiary"
      ctaButtons={ctaButtons()}
      onRequestClose={onCloseModal}
    >
      <ErrorModalContent>
        <ErrorLaptopImg />
        <Text as="h2">{t('error.popUps.headerPopUps')}</Text>
        <Text as="p">{t('surflyError.body1')}</Text>
        <Text as="p">{t('error.popUps.bodyPopUps')}</Text>
      </ErrorModalContent>
    </Modal>
  );
}

export default PopUpErrorModal;
