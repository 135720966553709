import React from 'react';
import { Link, resolvePath, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginReturnType } from 'contexts/UserContext';
import { pages } from 'pages';
import { EmailField } from 'shared/components/EmailField/EmailField';
import { PasswordField } from 'shared/components/PasswordField/PasswordField';
import { Button } from 'shared/components/Button/Button';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { useSendAccessCode } from 'api/account';
import { toast } from 'shared/components/Toast/Toast';
import { useTheme } from 'styled-components';
import { LoginFormStyled } from './LoginForm.styles';

export interface LoginFormInput {
  email: string;
  password: string;
}

export function LoginForm() {
  const navigate = useNavigate();
  const { login } = useUser();
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField,
    reset,
  } = useForm<LoginFormInput>({ mode: 'onSubmit' });

  const { mutate: sendAccessCode } = useSendAccessCode({
    onSuccess: (_, variables) => {
      const params = new URLSearchParams({ email: variables.email });
      navigate(`/${pages.VERIFICATION}?${params.toString()}`, { state: { isMFA: true } });
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('registration.invalidEmail'),
        theme,
      });
    },
  });

  const handleLoginSuccess = (data: LoginReturnType) => {
    if (data.requiresMFA) {
      sendAccessCode({ email: getValues('email') });
    } else {
      const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
        data.user,
        data.user?.accounts?.[0],
      );
      navigate(redirectPath);
      if (hasDashboardAccess) {
        navigate(resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`));
      } else navigate(redirectPath);
    }
  };

  const { mutate, isError, data } = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      if (!res.error) {
        handleLoginSuccess(res);
      } else if (res.errorData?.default_code === 'throttled') {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: t('toast.tooManyAttempts'),
          theme,
        });
      }
    },
    // unlikely to hit this since user provider handles errors from API call
    onError: () => {
      reset();
    },
  });

  const onSubmit: SubmitHandler<LoginFormInput> = ({ email, password }) => {
    mutate({ email, password });
  };

  const passwordError =
    isError || data?.error
      ? t('loginScreen.errors.AUTHENTICATION_FAILED')
      : errors.password?.message;

  return (
    <LoginFormStyled onSubmit={handleSubmit(onSubmit)}>
      <EmailField
        register={register}
        onClearInput={resetField}
        hasError={Boolean(errors.email)}
        errorMessage={errors.email?.message}
        margin={`0 0 ${screenSize === 'sm' ? '16px' : '20px'}`}
      />
      <PasswordField
        register={register}
        onClearInput={resetField}
        hasError={Boolean(passwordError)}
        errorMessage={passwordError}
        margin="0 0 12px"
      />
      <Link to={`/${pages.FORGOT_PASSWORD}`}>{t('loginScreen.forgotPassword')}</Link>
      <Button
        type="submit"
        label={t('loginScreen.login')}
        isFullWidth
        margin={`${screenSize === 'sm' ? '16px' : '32px'} 0 0`}
      />
    </LoginFormStyled>
  );
}

export default LoginForm;
