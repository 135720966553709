import Text from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const SystemCardTotalOutputValue = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.textPrimarySecondary1100};
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
    font-style: normal;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      font-size: 34px;
      font-weight: 700;
      line-height: 44px;
    }
  `}
`;

export const SystemCardTotalOutputValueSuffix = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.textPrimarySecondary1100};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    padding: 0px 0px 5px 8px;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }
  `}
`;

export const SystemCardTotalOutputValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 0 0 44px;
`;

export const SystemCardOutputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 20px;
  gap: 6px;
  background: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};
  box-shadow: ${({ theme }) => theme.elevations.extraSunken};
`;
