import React, { useMemo } from 'react';
import { format, parse } from 'date-fns';

import { Accordion, AccordionItemType } from 'shared/components/Accordion/Accordion';
import { AgreementType } from 'api/types';
import { formatMoney } from 'shared/utils/formatMoney';
import { formatInverterManufacturer } from '../../utils/formatInverterManufacturer';
import { AgreementSection } from './AgreementSection';

type Props = {
  agreements: AgreementType[];
  onChangeArchiveAgreement: (agreement: AgreementType) => void;
};
export function AgreementsAccordion({ agreements, onChangeArchiveAgreement }: Props) {
  const items = useMemo(
    (): AccordionItemType[] =>
      agreements.map((agreement) => {
        const handleChangeArchiveAgreement = () => {
          onChangeArchiveAgreement(agreement);
        };
        const { system } = agreement;

        const overviewList = [
          { label: 'Agreement', value: agreement.id },
          { label: 'Product Name', value: agreement.product_display_name },
          { label: 'Product Type', value: agreement.product_type },
          { label: 'Product Version', value: agreement.product_version?.toString() },
          { label: 'Current Milestone', value: agreement.current_milestone },
          { label: 'Archive Status', value: agreement.archived.toString() },
          {
            label: 'Great America Application IDs',
            value: agreement.great_america_application_ids?.toString(),
          },
        ];

        const contractDetailsList = [
          { label: 'Contract Type', value: agreement.contract_type },
          { label: 'Contract Term', value: `${agreement.contract_term} years` },
          { label: 'Homeowner Contract ID', value: agreement.homeowner_contract_id },
          {
            label: 'Contract Signed Date',
            value: format(new Date(agreement.contract_signed_date), 'MMM d, yyyy'),
          },
          {
            label: 'Contract Start Date',
            value: agreement.contract_start_date
              ? format(
                  parse(agreement.contract_start_date, 'yyyy-MM-dd', new Date()),
                  'MMM d, yyyy',
                )
              : 'N/A',
          },
          {
            label: 'In-Service Date',
            value: agreement.in_service_date
              ? format(agreement.in_service_date, 'MMM d, yyyy')
              : 'N/A',
          },
          { label: 'Platform UUID', value: agreement.platform_uuid },
        ];

        const financialsList = [
          { label: 'Install Cost', value: formatMoney(agreement.system.install_cost) },
          { label: 'Initial Balance', value: formatMoney(agreement.initial_balance) },
          { label: 'Monthly Payment', value: formatMoney(agreement.monthly_payment) },
          { label: 'Rate Contract', value: `${agreement.rate_contract}` },
          { label: 'Rate Escalator', value: `${agreement.rate_escalator}` },
          { label: 'Interest Rate', value: `${agreement.interest_rate}` },
          {
            label: 'Amount Financed',
            value: formatMoney(agreement.amount_financed || undefined),
            id: 'ag17',
          },
          { label: 'Buydown Amount', value: formatMoney(agreement.buydown_amount), id: 'ag18' },
        ];

        const systemList = [
          { label: 'Panels', value: system.panels },
          { label: 'Inverters', value: agreement.system.inverters },
          { label: 'Capacity', value: agreement.system.capacity?.toString() },
          { label: 'System Mounting', value: agreement.system.system_mounting_type },
          { label: 'Estimated Offset', value: agreement.system.estimated_offset },
          {
            label: 'Inverter Manufacturer',
            value: formatInverterManufacturer(system.inverter_manufacturer_id?.toString()) || '',
          },
          { label: 'Site ID', value: system.site_id?.toString() },
          {
            label: 'System Address',
            value: `${system.address.street_1}${system.address.street_2 ? ` ${system.address.street_2},` : ','} ${system.address.city} ${system.address.zip_code}`,
          },
          { label: 'Archive Status', value: system.archived.toString() },
        ];

        const grafanaLink = `https://g-178643a492.grafana-workspace.us-east-1.amazonaws.com/d/XbVV26MIz/solar-monitoring-telemetry?orgId=1&var-vendor_id=${system.vendor_id}&var-site_id=${system.site_id}&from=now-7d&to=now`;
        const monitoringVendorLink =
          system.vendor_id === 1
            ? `https://enlighten.enphaseenergy.com/systems/${system.site_id}/graphs?range=last7Days`
            : `https://monitoring.solaredge.com/solaredge-web/p/site/${system.site_id}`;

        return {
          label: `Agreement #${agreement.homeowner_contract_id}`,
          id: agreement.id,
          content: (
            <AgreementSection
              overviewList={overviewList}
              contractDetailsList={contractDetailsList}
              financialsList={financialsList}
              systemList={systemList}
              isArchived={agreement.archived}
              grafanaLink={system.site_id ? grafanaLink : null}
              monitoringVendorLink={
                system.site_id && system.vendor_id ? monitoringVendorLink : null
              }
              onToggleArchive={handleChangeArchiveAgreement}
            />
          ),
        };
      }),
    [agreements, onChangeArchiveAgreement],
  );

  return <Accordion items={items} styleVariant="tertiary" size="large" />;
}

export default AgreementsAccordion;
