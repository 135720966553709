import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from 'shared/components/Text/Text';

export const ChartDataErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 768px;
    height: 398px;
  }
`;
export const HeaderText = styled(Text)`
  font-family: Avenir;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const BodyText = styled(Text)`
  font-family: Avenir;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
  white-space: pre-wrap; /* Preserve newlines and whitespace */
`;

export const LinkStyled = styled(Link)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSystemInfoSecondary2100};
`;
