import styled, { css } from 'styled-components';
import { Color } from 'theme/colors';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { FONT_FAMILY } from 'shared/components/Text/Text';

export const DateSelectorWrapper = styled.div`
  display: flex;
  gap: 24px;
  button {
    gap: 0px;
  }
`;

export const DateHeading = styled.button<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${$isActive
      ? theme.colors.cardInteractionActiveSecondary420
      : theme.colors.backgroundPrimaryWhite};
    border: 1px solid ${theme.colors.strokePrimaryGrey20};
    border-radius: 6px;
    height: 40px;
    padding: 7px 12px;
    outline: none;
    p {
      margin: auto 0 0 4px;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.colors.textPrimarySecondary1100};
    }

    &:hover {
      background: ${$isActive
        ? theme.colors.cardInteractionActiveSecondary420
        : theme.colors.cardInteractionHoverGrey05};
    }
    &:active {
      background: ${theme.colors.cardInteractionActiveSecondary420};
    }
  `}
`;

export const StyledDayPicker = styled(DayPicker)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    margin: 0px;

    .custom-table {
      border-collapse: collapse;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      thead {
        width: 100%;
        tr {
          display: flex;
          justify-content: space-around;
        }
      }
    }

    .rdp-day {
      font-weight: normal;

    }

    .rdp-button:hover:not([disabled]):not(.rdp-day_selected):not(.custom-day-selected) {
      background: ${theme.colors.cardElevationMidWhite};
      border-width: 1px;
      border-color: ${theme.colors.strokeInteractionActiveStrongestSecondary1100};
    }

    .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
      background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
    }

    .rdp-button:focus-visible:not([disabled]) {
      outline: 0px;
      border: 1px solid ${theme.colors.strokeInteractionActiveStrongestSecondary1100};
      background: ${theme.colors.cardElevationMidWhite};
      box-shadow: 0px 0px 0px 1px #FFF, 0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
      &.custom-day-selected {
        background: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
        color: ${theme.colors.textInverseWhite};
      }
    }
  `}
`;

export const PickerWrapper = styled.div<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    position: absolute;
    top: 68px;
    left: 80px;
    z-index: 999;
    display: ${$isActive ? 'block' : 'none'};
    background: ${theme.colors.cardElevationMidWhite};
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.mid};
    border-radius: 8px;
    padding: 16px 16px 8px;
    margin-top: -8px;
    font-family: ${FONT_FAMILY};

    .custom-month {
      h2 {
        margin: 5px;
        color: ${theme.colors.textPrimarySecondary1100};
        font-weight: 400;
        font-size: 14px;
      }
    }

    .custom-head-cell {
      font-size: 14px;
      line-height: 20px;
      width: 16px;
      padding: 0px 14px;
      font-weight: 400;
      color: ${theme.colors.textSecondarySubtleGrey80};
      text-transform: unset;
    }

    .custom-nav-prev,
    .custom-nav-next {
      color: ${theme.colors.textPrimarySecondary1100};
      height: 22px;
      width: 22px;
      margin: 5px;

      &:hover:not([disabled]) {
        border-width: 1px;
        border-color: ${theme.colors.strokeInteractionActiveStrongestSecondary1100};
        background-color: rgba(0, 0, 0, 0);
      }
    }

    .custom-cell {
      --rdp-cell-size: 32px;
      font-weight: 400;
      font-size: 14px;
      padding: 6px;
      color: ${theme.colors.textPrimarySecondary1100};
      :hover:not([disabled]) {
        border-width: 1px;
        border-color: ${theme.colors.strokeInteractionActiveStrongestSecondary1100};
        background-color: ${theme.colors.backgroundPrimaryWhite};
        &.custom-day-selected {
          background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
        }

        &.custom-day-range-start {
          color: ${theme.colors.textInverseWhite};
          background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
        }
      }
    }

    .custom-day-selected {
      color: ${theme.colors.textInverseWhite};
      background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
      border-width: 1px;
    }

    .custom-day-range-start {
      color: ${theme.colors.textInverseWhite};
      background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
      overflow: visible;
    }

    .custom-day-range-start:not(.custom-day-range-end):after {
      /* Cool, continuous blue streak that goes from start to end */
      content: '';
      background: ${theme.colors.backgroundInteractionPressedSecondary4100};
      position: absolute;
      z-index: -1;
      left: calc(50% + 2px);
      height: calc(100% + 2px);
      width: calc(50% + 9px);
    }

    .custom-day-range-middle {
      color: ${theme.colors.textPrimarySecondary1100};
      border-radius: 50%;
      background-color: ${theme.colors.backgroundInteractionPressedSecondary4100};
      overflow: visible;
      pointer-events: none;
    }

    .custom-day-range-middle:after {
      /* Cool, continuous blue streak that goes from start to end */
      content: '';
      background: ${theme.colors.backgroundInteractionPressedSecondary4100};
      position: absolute;
      z-index: -1;
      height: calc(100% + 2px);
      width: calc(100% + 18px);
    }

    .custom-day-range-end:not(.custom-day-range-start) {
      color: ${theme.colors.textInverseWhite};
      background-color: ${theme.colors.backgroundAccentBlueStrongestSecondary1100};
      border-color: ${theme.colors.textPrimarySecondary1100};
      overflow: visible;
    }

    .custom-day-range-end:not(.custom-day-range-start):after {
      /* Cool, continuous blue streak that goes from start to end */
      content: '';
      background: ${theme.colors.backgroundInteractionPressedSecondary4100};
      position: absolute;
      z-index: -1;
      left: -5.5px;
      height: calc(100% + 2px);
      width: calc(50% + 5.5px);
    }
  `}
`;

export const CustomPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginationWrapper = styled.div<{ $hasHeaderSpacer?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ $hasHeaderSpacer }) =>
    $hasHeaderSpacer
      ? `
    height: 25px;
    margin-bottom: 20px;
  `
      : `
    margin-bottom: 5px;
  `}
`;

export const PaginationHeader = styled.h4<{ size?: string }>`
  ${({ theme, size }) => `
    font-size: ${size === 'small' ? '14px' : '16px'};
    font-weight: ${size === 'small' ? '700' : '600'};
    line-height: 24px;
    color: ${theme.colors.textPrimarySecondary1100};
    margin: 0px 24px;
  `}
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type PaginationButtonProps = {
  margin?: string;
  isFullWidth?: boolean;
  maxWidth?: string;
  color?: Color;
  disabled?: boolean;
};

export const PaginationButton = styled.button<PaginationButtonProps>`
  ${({ isFullWidth, maxWidth, color, disabled, theme }) => `
      display: flex;
      :disabled {
        font-weight: 100;
        color: ${theme.colors.textSystemDisabledGrey50};
      }
      cursor: pointer;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: ${color};
      background: transparent;
      border: none;
      border-radius: 4px;
      padding: 0px;
      width: ${isFullWidth ? '100%' : 'auto'};
      max-width: ${maxWidth || 'none'};
      &:hover {
        color: ${theme.colors.iconIconPrimaryHoverFunction4100};
        cursor: ${disabled ? 'default' : 'pointer'};
      }
      &:active {
        color: ${theme.colors.iconIconPrimaryHoverFunction4100};
      }
    `}
`;

export const CustomPickerGridWrapper = styled.div<{ $timeScale?: 'month' | 'year' }>`
  ${({ $timeScale }) => `
    display: grid;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 44px);
    grid-template-rows: repeat(4, 44px);
    grid-column-gap: ${$timeScale === 'month' ? '16px' : '28px'};
  `}
`;

export const CustomPickerButton = styled.button<{
  selected: boolean;
  disabled: boolean;
  $timeScale?: 'month' | 'year';
}>`
  ${({ selected, disabled, theme, $timeScale }) => `
      color: ${selected ? theme.colors.textInverseWhite : theme.colors.textPrimarySecondary1100};
      :disabled {
        font-weight: 100;
        color: ${theme.colors.textSystemDisabledGrey50};
      }
      font-family: ${FONT_FAMILY};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 32px;
      width: ${$timeScale === 'month' ? '44px' : '52px'};
      line-height: 20px;
      text-align: center;
      padding: 6px;
      background-color: ${
        selected ? theme.colors.backgroundAccentBlueStrongestSecondary1100 : 'transparent'
      };
      border: none;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid ${
          disabled ? theme.colors.textInverseWhite : theme.colors.textPrimarySecondary1100
        };
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      }
  `}
`;

export const InlineButtonWrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  flex-direction: row;
  align-self: flex-end;
  padding: 0px 8px;
  button {
    margin-left: 32px;
    padding-right: 8px;
    display: flex;
    height: fit-content;
    min-width: 0px;
    padding: 10px 0px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    color: ${theme.colors.textSystemInfoSecondary2100};
  }
`}
`;

export const ChevronButtonWrapper = styled.div<{ $isVisible: boolean }>`
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
`;
