import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function BoltLightIcon({ color: colorProp }: IconProps) {
  const theme = useTheme();
  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2843_639)">
        <path
          d="M8.5875 0.1965C8.73125 0.0699062 8.91562 0 9.10625 0C9.64375 0 10.0187 0.532813 9.8375 1.03969L7.70937 7H11.1281C11.6219 7 12 7.37813 12 7.84375C12 8.0875 11.8969 8.31875 11.7156 8.47812L3.40937 15.8062C3.26875 15.9312 3.08437 16 2.89469 16C2.35656 16 1.98094 15.4656 2.16188 14.9594L4.29063 9H0.806562C0.36125 9 0 8.6375 0 8.19375C0 7.9625 0.0985312 7.74375 0.27075 7.59062L8.5875 0.1965ZM8.60625 1.52031L1.315 8H5C5.1625 8 5.31563 8.07812 5.40938 8.2125C5.50313 8.34375 5.525 8.51562 5.47188 8.66875L3.39375 14.4875L10.7437 8H7C6.8375 8 6.68437 7.92188 6.59062 7.7875C6.49687 7.62812 6.475 7.48438 6.52812 7.33125L8.60625 1.52031Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2843_639">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BoltLightIcon;
