import { FONT_FAMILY } from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';
import { breakpointValues } from 'shared/design-system/theme/breakpoints';
import {
  MonitoringSnapshotWrapper,
  StatisticLabel,
  StatisticValue,
  StatisticWrapper,
} from '../../components/MonitoringSnapshot/MonitoringSnapshot.styles';

/** Represents the minimum screen space occupied by the nav bar–it's being used
 * here to trigger a slightly earlier than usual jump to medium-screen styling to
 * avoid crowding the system screen
 */
const NAV_BAR_WIDTH = 214;

export const ChartAndSelectors = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SystemChartHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: unset;
  }
`;

export const SystemSideCards = styled.div<{ $isChartPresent?: boolean }>`
  ${({ theme, $isChartPresent }) => css`
    font-size: 16px;
    background: ${theme.colors.backgroundPrimaryWhite};
    line-height: 1.5;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 20px;
    padding: 0 8px;
    display: flex;
    flex-direction: ${$isChartPresent ? 'column' : 'row'};

    > div {
      margin-right: ${$isChartPresent ? '0px' : '24px'};
    }

    @media screen and (min-width: ${theme.breakpoints.small}) {
      flex-direction: row;
      gap: 24px;
      height: 236px;
    }

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      height: unset;
      width: 344px;
      flex-direction: ${$isChartPresent ? 'column' : 'row'};
      gap: 32px;
    }
  `}
`;

export const OmnidianHardwareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    gap: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 32px;
  }
`;

export const SystemPageBodyWrapper = styled.div`
  display: flex;
  min-width: 100%;
  gap: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    gap: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 48px;
  }
`;

export const SystemPageWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-family: ${FONT_FAMILY};
    background: ${theme.colors.backgroundPrimaryWhite};
    h1 {
      color: ${theme.colors.textPrimarySecondary1100};
    }

    @media (${theme.breakpoints.small} < width <= ${breakpointValues.medium + NAV_BAR_WIDTH}px) {
      h1 {
        font-size: 24px;
        margin-bottom: 18px;
      }
      ${StatisticWrapper} {
        ${StatisticLabel} {
          font-size: 16px;
        }
        ${StatisticValue} {
          font-size: 16px;
          span:first-of-type {
            font-weight: 700;
          }
        }
      }
      ${SystemPageBodyWrapper} {
        flex-direction: column;
      }
    }

    @media (width <= ${theme.breakpoints.small}) {
      h1 {
        font-size: 24px;
        margin-bottom: 18px;
      }
      ${MonitoringSnapshotWrapper} {
        width: 100%;
        row-gap: 12px;
        margin-bottom: 22px;
        ${StatisticLabel} {
          font-size: 14px;
        }
        ${StatisticValue} {
          font-size: 16px;
          span:first-of-type {
            font-weight: 700;
          }
        }
      }
      ${SystemPageBodyWrapper} {
        flex-direction: column;
      }
    }
  `}
`;
