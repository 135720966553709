import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { toast } from 'shared/components/Toast/Toast';
import { useDownloadContract } from 'api/agreement';
import { AgreementType } from 'api/types';
import { MilestoneType, WelcomeChecklistStatusType } from 'modules/types';
import { Button } from 'shared/components/Button/Button';
import { DownloadIcon } from 'shared/components/icons';
import { ButtonFooter } from './ProjectQualification.styles';
import { ProjectQualificationCopy } from './ProjectQualificationCopy';

export type ProjectQualificationContentProps = {
  isLoadingWelcomeChecklistQuestions: boolean;
  agreement?: AgreementType;
  isCurrentMilestone: boolean;
  onOpenWelcomeChecklist: () => void;
};

export function ProjectQualification({
  isLoadingWelcomeChecklistQuestions,
  agreement,
  isCurrentMilestone,
  onOpenWelcomeChecklist,
}: ProjectQualificationContentProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate: download, isLoading } = useDownloadContract({
    onSuccess: (data) => {
      const { presigned_url: presignedUrl } = data;
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = 'signed_everbright_contract.pdf';
      link.click();
      link.remove();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    },
  });

  const handleDownload = async () => {
    if (agreement) {
      download(agreement.id);
    } else {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    }
  };

  const shouldShowContent: boolean = useMemo(
    () =>
      !!agreement &&
      !agreement.is_tpv_installer &&
      agreement.current_milestone === MilestoneType.CONTRACT_SIGNED &&
      agreement.welcome_checklist_status !== WelcomeChecklistStatusType.NOT_REQUIRED &&
      agreement.welcome_checklist_status !== WelcomeChecklistStatusType.PASSED,
    [agreement],
  );

  const handleOpenWelcomeChecklist = () => {
    onOpenWelcomeChecklist();
  };

  return agreement ? (
    <div>
      <ProjectQualificationCopy
        welcomeChecklistStatus={agreement.welcome_checklist_status}
        isCurrentMilestone={isCurrentMilestone}
      />
      {shouldShowContent &&
        agreement.welcome_checklist_status === WelcomeChecklistStatusType.REQUIRED && (
          <ButtonFooter>
            <Button
              onClick={handleDownload}
              label="Contract"
              iconRight
              Icon={DownloadIcon}
              styleVariant="tertiary"
              margin="0 0 0 auto"
              isLoading={isLoading}
            />

            <Button
              label="Complete Checklist"
              onClick={handleOpenWelcomeChecklist}
              margin="0 0 0 20px"
              isLoading={isLoadingWelcomeChecklistQuestions}
            />
          </ButtonFooter>
        )}
    </div>
  ) : null;
}

export default ProjectQualification;
