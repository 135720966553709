import React from 'react';

import {
  SystemCardIndividualOutputWrapper,
  SystemCardIndividualOutputHeader,
  SystemCardIndividualOutputValue,
  SystemCardIndividualOutputValueSuffix,
} from './SystemCardIndividualOutput.styles';

type SystemCardIndividualOutputProps = {
  header: string;
  value: string;
  suffix: string;
};

export default function SystemCardIndividualOutput({
  header,
  value,
  suffix,
}: SystemCardIndividualOutputProps) {
  return (
    <SystemCardIndividualOutputWrapper data-is-individual-output>
      <SystemCardIndividualOutputHeader as="h5">{header}</SystemCardIndividualOutputHeader>
      <SystemCardIndividualOutputValue as="h3">
        {value}
        <SystemCardIndividualOutputValueSuffix as="p">
          {suffix}
        </SystemCardIndividualOutputValueSuffix>
      </SystemCardIndividualOutputValue>
    </SystemCardIndividualOutputWrapper>
  );
}
