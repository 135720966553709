/* Add all URL paths and parameter names here */

const login = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot_password',
  CHECK_EMAIL: 'check_email',
  RESET_PASSWORD: 'reset_password/:token',
  CLAIM_ACCOUNT: 'claim_account/:token',
  REGISTRATION: 'registration',
  VERIFICATION: 'verification',
  PASSWORD: 'password',
  TOO_MANY_ATTEMPTS: 'too_many_attempts',
} as const;

const dashboard = {
  ACCOUNT: 'account',
  AGREEMENTS: 'agreements',
  AGREEMENT: 'agreement/:agreementId',
  BILLING: 'billing',
  DASHBOARD: 'dashboard',
  FAQ: 'faq/:faqId',
  HARDWARE_INFO: 'hardware_information',
  OVERVIEW: 'overview',
  SUPPORT: 'support',
  SYSTEM: 'system',
  SURFLY_LOADING: 'screen-share-loading',
} as const;

const admin = {
  ADMIN: 'admin',
  ADMINS: 'admins',
  USERS: 'users',
  USER: 'users/:userId',
  STATISTICS: 'statistics',
  MANAGE_FAQS: 'manage_faqs',
  AUTH_COPY_ACK: 'auth_copy_ack',
  BACKFILL: 'backfill',
} as const;

export const pages = {
  ...login,
  ...dashboard,
  ...admin,
  AUTHORIZE: 'authorize',
  CHAT: 'chat',
  ERROR: 'error',
  STATUS: 'status',
  ERROR_SURFLY: 'screen-share-error',
} as const;

export type PageKey = keyof typeof pages;

export type Page = (typeof pages)[PageKey];

export default pages;
