import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath } from 'react-router';
import { useTheme } from 'styled-components';
import { useQuery, useQueryClient } from 'react-query';
import { startOfToday, endOfToday } from 'date-fns';

import { pages } from 'pages';
import { Card } from 'shared/components/Card/Card';
import { BoltLightIcon } from 'shared/components/icons/BoltLightIcon';
import { IconPosition } from 'shared/components/Card/Card.styles';

import { DateRangeZoomLevel, EnergyUnit, RawMonitoringDatumType, SystemType } from 'api/types';
import {
  MonitoringQueryKey,
  prefetchSystemCardQueries,
} from 'modules/system/utils/prefetchQueries';

import { getSolarEnergyWithEnergyAllocation } from 'api/system';
import { SystemCardBody, SystemCardWrapper } from './SystemCard.styles';
import SystemCardOutputsSection from './SystemCardOutputsSection';
import SystemCardTotalOutputText from './SystemCardTotalOutputText';

const aggregateSolarEnergyData = (data: RawMonitoringDatumType<EnergyUnit>[]): string =>
  data
    .reduce((acc: number, _data: RawMonitoringDatumType<EnergyUnit>) => acc + _data.value, 0)
    .toFixed(1);

type TodaysSystemDataQueriesResult = {
  solarEnergyKwhToday: string;
  gridEnergyKwhToday: string;
  homeEnergyKwhToday: string;
  batteryEnergyKwhToday: string;
};
export const useTodaysSystemDataValues = (
  system: SystemType | undefined,
): TodaysSystemDataQueriesResult => {
  const todayDateRange = {
    startDate: startOfToday(),
    endDate: endOfToday(),
    zoomLevel: DateRangeZoomLevel.WEEK, // Use WEEK so that `aggregation_level=day` is used
  };
  const { data: solarEnergyTodayDataWithEnergyAllocation } = useQuery(
    [MonitoringQueryKey.ENERGY_TODAY_WITH_ENERGY_ALLOCATION, system?.id],
    () =>
      getSolarEnergyWithEnergyAllocation({
        systemId: system!.id,
        dateRange: todayDateRange,
      }).then((response) => response.data),
    { enabled: !!system },
  );
  const energyAllocation = solarEnergyTodayDataWithEnergyAllocation?.energy_allocation;
  const solarEnergyKwhToday = solarEnergyTodayDataWithEnergyAllocation
    ? aggregateSolarEnergyData(solarEnergyTodayDataWithEnergyAllocation.monitoring_data)
    : '';

  const gridEnergyKwhToday =
    energyAllocation && system?.has_grid_monitoring
      ? (energyAllocation.production_to_grid_wh / 1000).toFixed(1)
      : '';
  const homeEnergyKwhToday =
    energyAllocation && system?.has_home_monitoring
      ? (energyAllocation.production_to_home_wh / 1000).toFixed(1)
      : '';
  const batteryEnergyKwhToday =
    energyAllocation && system?.has_battery_monitoring
      ? (energyAllocation.production_to_battery_wh / 1000).toFixed(1)
      : '';

  return {
    solarEnergyKwhToday,
    gridEnergyKwhToday,
    homeEnergyKwhToday,
    batteryEnergyKwhToday,
  };
};

type SystemCardProps = {
  system: SystemType | undefined;
};

export function SystemCard({ system }: SystemCardProps) {
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    async function prefetchQueries() {
      if (system) await prefetchSystemCardQueries({ queryClient, system });
    }

    if (
      !queryClient.getQueryData([
        MonitoringQueryKey.ENERGY_TODAY_WITH_ENERGY_ALLOCATION,
        system?.id,
      ])
    ) {
      prefetchQueries();
    }
  }, [queryClient, system]);
  const { solarEnergyKwhToday, gridEnergyKwhToday, homeEnergyKwhToday, batteryEnergyKwhToday } =
    useTodaysSystemDataValues(system);
  return (
    <SystemCardWrapper>
      <Card
        title={t('system.totalSolarProduction')}
        icon={{
          element: <BoltLightIcon color={colors.iconIconPrimarySecondary1100} />,
          color: 'grey',
        }}
        iconPosition={IconPosition.NEXT}
        route={resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname}
      >
        <SystemCardBody>
          <SystemCardTotalOutputText value={solarEnergyKwhToday} />
          {system?.has_grid_monitoring && system?.has_home_monitoring && (
            <SystemCardOutputsSection
              homeEnergy={homeEnergyKwhToday}
              gridEnergy={gridEnergyKwhToday}
              batteryEnergy={batteryEnergyKwhToday}
              hasBatteryMonitoring={system ? system.has_battery_monitoring : false}
            />
          )}
        </SystemCardBody>
      </Card>
    </SystemCardWrapper>
  );
}

export default SystemCard;
