import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function WrenchIcon({ color: colorProp }: IconProps) {
  const theme = useTheme();
  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2843_13898)">
        <path
          d="M15.8555 3.57778C15.7852 3.28093 15.5548 3.04658 15.2618 2.9644C14.9649 2.88238 14.6484 2.9644 14.4297 3.18313L12.4339 5.17764L11.0511 4.94719L10.8207 3.5645L12.8168 1.56843C13.0316 1.35361 13.1176 1.03317 13.0356 0.740382C12.9535 0.443534 12.7153 0.21293 12.4184 0.138874C10.8013 -0.259528 9.12484 0.201368 7.94054 1.38095C6.75607 2.566 6.30922 4.27772 6.7342 5.87757L0.693925 11.9177C-0.231959 12.8435 -0.231959 14.3512 0.693925 15.2767C1.13984 15.7517 1.73761 15.9985 2.37445 15.9985C3.00723 15.9985 3.60094 15.7525 4.05029 15.3033L10.0843 9.26945C11.7133 9.6991 13.4248 9.24602 14.6122 8.05456C15.7962 6.84624 16.2618 5.19951 15.8555 3.57778ZM13.9057 7.34932C12.9135 8.34141 11.4564 8.68138 10.0996 8.2283L9.80665 8.13065L3.31565 14.5955C2.79599 15.1152 1.89386 15.1152 1.3742 14.5955C0.838916 14.0603 0.838916 13.1854 1.3742 12.6504L7.84257 6.18223L7.77164 5.8932C7.32229 4.53395 7.65837 3.07689 8.64659 2.08729C9.35749 1.38017 10.2949 0.99739 11.2639 0.99739C11.4788 0.99739 11.6977 1.01692 11.9123 1.05207L9.74809 3.22L10.1778 5.81352L12.7754 6.24723L14.9397 4.0793C15.1525 5.27763 14.7744 6.48377 13.9057 7.34932ZM2.49976 12.9707C2.2224 12.9707 1.99979 13.1933 1.99979 13.4706C1.99979 13.748 2.22243 13.9706 2.49976 13.9706C2.77709 13.9706 2.99973 13.748 2.99973 13.4706C2.99973 13.1933 2.77693 12.9707 2.49976 12.9707Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2843_13898">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WrenchIcon;
