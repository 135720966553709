import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { FullStory } from '@fullstory/browser';

import routes from 'routes';
import { login as loginApi, logout as logoutApi } from 'api/account';
import { AccountType, LoginRequestBody, UserType } from 'api/types';
import { authenticatedKey } from 'constants/localStorageKeys';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { authCopyAckNeeded } from 'shared/components/AuthCopyAckModal/utils/authCopyAckNeeded';
import { removeLocalStorageItems } from './removeLocalStorageItems';
import { getActiveAccount } from './getActiveAccount';

/* intentional */
const noop = (): Promise<UserResult | undefined> => new Promise(() => {});
const noop2 = (): Promise<LoginReturnType> => new Promise(() => {});

interface UserResult {
  user: UserType | null;
  impersonator?: UserType;
  error?: Error | string;
  loading?: boolean;
  selectedAccount?: AccountType;
}

export type LoginReturnType = {
  error: boolean;
  user?: UserType;
  requiresMFA?: boolean;
  errorData?: Record<string, string>;
};

interface UserContextProps {
  userResult?: UserResult;
  setUserResult: (UserResult: UserResult) => void;
  refetch: () => Promise<UserResult | undefined>;
  login: ({ email, password }: LoginRequestBody) => Promise<LoginReturnType>;
  logout: () => void;
  clearUserState: () => void;
}

const UserContext = React.createContext<UserContextProps>({
  userResult: undefined,
  setUserResult: noop,
  refetch: noop,
  login: noop2,
  logout: noop,
  clearUserState: noop,
});

/**
 * Provider to store the user's current profile.
 */

const userResultDefault = {
  user: null,
  impersonator: undefined,
  error: undefined,
  loading: true,
  selectedAccount: undefined,
};

interface Props {
  children: React.ReactNode;
}

function UserProvider({ children }: Props) {
  const authenticated = localStorage.getItem(authenticatedKey);
  // Store the current user in state
  const [userResult, setUserResult] = useState<UserResult>(userResultDefault);

  const asyncSetUserResult = useCallback(async () => {
    let payload: UserResult = {
      user: null,
      impersonator: undefined,
      error: undefined,
      loading: false,
      selectedAccount: undefined,
    };
    if (authenticated) {
      try {
        const response = await axios.get(routes.me);

        const { accounts } = response.data.user;
        const activeAgreementAccount = accounts.find(
          (account: AccountType) => account.agreements?.length,
        );

        const selectedAccount = activeAgreementAccount || accounts[0];

        payload = {
          user: response.data.user,
          impersonator: response.data?.impersonator,
          error: undefined,
          loading: false,
          selectedAccount,
        };

        const name = `${response.data.user.first_name} ${response.data.user.last_name}`;
        const userEmail = response.data.user.email;
        const email = response.data.user.impersonator
          ? `${response.data.user.impersonator.email} as ${userEmail}`
          : userEmail;
        datadogRum.setUser({
          id: response.data.user.id,
          email,
          name,
          role: response.data.user.role,
        });

        const currentMilestone = selectedAccount?.agreements[0].current_milestone;
        const { user } = response.data;
        const { hasDashboardAccess } = getDashboardRedirectPath(user, selectedAccount);
        FullStory('setIdentity', {
          uid: response.data.user.id,
          properties: {
            authCopyAcknowledgementRequired: authCopyAckNeeded(selectedAccount),
            currentMilestone,
            displayName: name,
            email,
            pto: hasDashboardAccess,
            role: response.data.user.role,
          },
        });
      } catch (e) {
        let error: Error | string = 'Unknown Error';

        if (e instanceof Error) error = e;

        payload = { ...userResultDefault, error, loading: false };
      }
    }
    setUserResult(payload);
    return payload;
  }, [authenticated]);

  // Fetch the user when this component is rendered
  useEffect(() => {
    asyncSetUserResult();
  }, [asyncSetUserResult]);

  const login = useCallback(
    async ({ email, password }: LoginRequestBody): Promise<LoginReturnType> => {
      try {
        const response = await loginApi({ email, password });
        localStorage.setItem(authenticatedKey, JSON.stringify(true));
        const { user, requires_MFA: requiresMFA } = response.data;

        if (requiresMFA) {
          return { error: false, requiresMFA };
        }

        setUserResult({
          user,
          loading: false,
          selectedAccount: getActiveAccount(user),
        });
        return { error: false, user: response.data };
      } catch (e) {
        const error = e as AxiosError<{ message: string }>;
        return { error: true, errorData: error.response?.data };
      }
    },
    [],
  );

  const resetUserState = () => {
    removeLocalStorageItems();
    const resetUser = { ...userResultDefault, loading: false };
    setUserResult(resetUser);
    datadogRum.clearUser();
  };

  /**
   * Clear all user state.
   * Called when the session has already been expired on the BE and we want to return user to the login page.
   */
  const clearUserState = useCallback(resetUserState, []);

  const logout = useCallback(async () => {
    try {
      await logoutApi();
      resetUserState();
    } catch (e) {
      // TODO(bzuhair): no-op catch block so that an error doesn't crash
      //  the app until error handling is implemented in HOP-536
    }
  }, []);

  const value = useMemo(() => {
    const refetch = async () => asyncSetUserResult();

    return {
      userResult,
      setUserResult,
      refetch,
      login,
      logout,
      clearUserState,
    };
  }, [asyncSetUserResult, userResult, login, logout, clearUserState]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export { UserProvider, UserContext };
