import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';

import { ContractType, DateRangeZoomLevel, SiteTimezoneResponse } from 'api/types';
import {
  addDays,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
} from 'date-fns';
import { DateRange } from 'api/system/utils/DateRange';
import { LINKS } from 'shared/links';
import { useUser } from 'hooks/useUser';
import { pages } from 'pages';
import { Header } from 'shared/components/Header/Header';
import { ChartWrapper } from 'modules/system/components/Charts/Chart.styles';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { DisplayChartError } from 'modules/system/components/ChartDataError/ChartDataError';
import { MonitoringQueryKey } from 'modules/system/utils/prefetchQueries';
import { isSystemDateYesterday } from 'modules/system/utils/ChartDataProviders/getSystemTimezoneConversion';
import { getSiteTimezone } from 'api/system';
import { TimescaleEnum } from 'modules/system/components/DateSelector/utils/showArrows';
import { WrenchIcon } from 'shared/components/icons/WrenchIcon';
import { GearIcon } from 'shared/components/icons/GearIcon';
import { ManageCard } from 'modules/overview/components/ManageCard/ManageCard';
import {
  ChartAndSelectors,
  OmnidianHardwareWrapper,
  SystemChartHeader,
  SystemPageBodyWrapper,
  SystemPageWrapper,
  SystemSideCards,
} from './SystemPage.styles';
import { DateSelector } from '../../components/DateSelector/DateSelector';
import { TimescaleControl } from '../../components/TimescaleControl/TimescaleControl';
import { MonitoringSnapshot } from '../../components/MonitoringSnapshot/MonitoringSnapshot';
import { getZoomLevelFromLocalTimescale } from '../../utils/getZoomLevelFromLocalTimescale';
import { DayChart } from '../../components/Charts/DayChart/DayChart';
import { EnergyChart } from '../../components/Charts/EnergyChart/EnergyChart';
import { WeekChartDataProvider } from '../../utils/ChartDataProviders/WeekChartDataProvider';
import { MonthChartDataProvider } from '../../utils/ChartDataProviders/MonthChartDataProvider';
import { YearChartDataProvider } from '../../utils/ChartDataProviders/YearChartDataProvider';
import { SystemCard } from '../../../overview/components/SystemCard/SystemCard';

export function SystemPage() {
  const { selectedAccount } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [dateRanges, setDateRanges] = useState<Partial<Record<DateRangeZoomLevel, DateRange>>>({});
  const [chartError, setChartError] = useState<null | DisplayChartError>(null);
  const [siteTimezone, setSiteTimezone] = useState<string>();

  // set local timescale labels
  const localTimescales = {
    day: t('datePicker.day'),
    week: t('datePicker.week'),
    month: t('datePicker.month'),
    year: t('datePicker.year'),
  };

  const [selectedTimescale, setSelectedTimescale] = useState(localTimescales.day);

  const { system, inServiceDate } = useMemo(
    () => getMonitoringSystemInfo(selectedAccount),
    [selectedAccount],
  );

  // if the system hasn't had its timezone set by the backend yet we can grab it here
  useEffect(() => {
    if (system && !system.site_timezone) {
      const tzQueryKey = [MonitoringQueryKey.TIMEZONE, system.id];
      const cachedTZ: SiteTimezoneResponse | undefined = queryClient.getQueryData(tzQueryKey);
      if (cachedTZ) {
        setSiteTimezone(cachedTZ.timezone);
      } else {
        queryClient.fetchQuery([MonitoringQueryKey.TIMEZONE, system.id], () =>
          getSiteTimezone(system.id).then((response) => {
            setSiteTimezone(response.data.timezone);
          }),
        );
      }
    }
  }, [system, queryClient]);

  // get "today" for system
  const today = useMemo(() => {
    const tz = system?.site_timezone || siteTimezone;
    return tz && isSystemDateYesterday(tz) ? startOfYesterday() : startOfToday();
  }, [system, siteTimezone]);

  // set initial dateRange information with today on mount
  useEffect(
    () => {
      // sets the start of a daily date range to the start of the day in local time
      const newDateRange: DateRange = {
        startDate: today,
        endDate: addDays(today, 1),
        zoomLevel: DateRangeZoomLevel.DAY,
      };

      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{
          [DateRangeZoomLevel.DAY]: newDateRange,
        },
      });
    },
    // disabling because we only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // if no systems have monitoring go to hardware details page
  useEffect(() => {
    if (selectedAccount && !system) {
      navigate(pages.HARDWARE_INFO, { state: { system: selectedAccount.agreements[0]?.system } });
    }
  }, [selectedAccount, system, navigate]);

  const handleDateChange = (date: Date) => {
    if (!dateRange) {
      return;
    }
    const newDateRange = new DateRange(date, dateRange.zoomLevel);
    setDateRange(newDateRange);
    setDateRanges({ ...dateRanges, ...{ [dateRange.zoomLevel]: newDateRange } });
  };

  const handleTimescaleChange = (timescale: string) => {
    setSelectedTimescale(timescale);
    const zoomLevel = getZoomLevelFromLocalTimescale(timescale, localTimescales);
    const existingDateRange = dateRanges[zoomLevel];
    if (existingDateRange) {
      setDateRange(existingDateRange);
    } else {
      let startDate = zoomLevel === DateRangeZoomLevel.YEAR ? startOfMonth(today) : today;
      switch (zoomLevel) {
        case DateRangeZoomLevel.WEEK:
          startDate = startOfWeek(startDate);
          break;
        case DateRangeZoomLevel.MONTH:
          startDate = startOfMonth(startDate);
          break;
        case DateRangeZoomLevel.YEAR:
          startDate = startOfYear(startDate);
          break;
        default:
          break;
      }
      const newDateRange = new DateRange(startDate, zoomLevel);
      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{ [zoomLevel]: newDateRange },
      });
    }
  };

  const showOmnidianCard =
    selectedAccount?.agreements.every(
      (agreement) => agreement.contract_type === ContractType.PPA,
    ) ||
    selectedAccount?.agreements.every((agreement) => agreement.contract_type === ContractType.LSE);

  let timescaleMapped = TimescaleEnum.DAY;
  if (selectedTimescale === localTimescales.week) {
    timescaleMapped = TimescaleEnum.WEEK;
  } else if (selectedTimescale === localTimescales.month) {
    timescaleMapped = TimescaleEnum.MONTH;
  } else if (selectedTimescale === localTimescales.year) {
    timescaleMapped = TimescaleEnum.YEAR;
  }

  return (
    <SystemPageWrapper>
      <Header pageName="SYSTEM" title={t('system.title')} />

      {!chartError && <MonitoringSnapshot system={system} />}

      {system && (
        <SystemPageBodyWrapper>
          {dateRange && inServiceDate && (
            <ChartAndSelectors>
              <SystemChartHeader>
                <TimescaleControl
                  timescaleOptions={localTimescales}
                  activeTimescale={selectedTimescale}
                  handleSetTimescale={handleTimescaleChange}
                />

                <DateSelector
                  timescaleOptions={localTimescales}
                  timescale={selectedTimescale}
                  timescaleEnum={timescaleMapped}
                  currentDate={today}
                  selectedDate={dateRange.startDate}
                  onDateChange={handleDateChange}
                  dataRange={{ oldest: dateRange.startDate, newest: dateRange.endDate }}
                  inServiceDate={inServiceDate}
                />
              </SystemChartHeader>

              <ChartWrapper>
                {selectedTimescale === localTimescales.day && (
                  <DayChart
                    dateRange={dateRange}
                    system={system}
                    chartError={chartError}
                    onError={setChartError}
                  />
                )}
                {selectedTimescale === localTimescales.week && (
                  <EnergyChart
                    dataProvider={new WeekChartDataProvider()}
                    dateRange={dateRange}
                    system={system}
                    chartError={chartError}
                    onError={setChartError}
                  />
                )}
                {selectedTimescale === localTimescales.month && (
                  <EnergyChart
                    dataProvider={new MonthChartDataProvider(t)}
                    dateRange={dateRange}
                    system={system}
                    chartError={chartError}
                    onError={setChartError}
                  />
                )}
                {selectedTimescale === localTimescales.year && (
                  <EnergyChart
                    dataProvider={new YearChartDataProvider()}
                    dateRange={dateRange}
                    system={system}
                    chartError={chartError}
                    onError={setChartError}
                  />
                )}
              </ChartWrapper>
            </ChartAndSelectors>
          )}
          <SystemSideCards $isChartPresent={Boolean(dateRange && inServiceDate)}>
            <SystemCard system={system} />

            <OmnidianHardwareWrapper>
              {showOmnidianCard && (
                <ManageCard
                  title={t('system.systemService.title')}
                  subHeader={t('system.systemService.copy')}
                  url={LINKS.EVERBRIGHT_OMNIDIAN_SERVICE_REQUEST}
                  iconElement={<WrenchIcon />}
                />
              )}

              <ManageCard
                title={t('system.hardwareDetails.title')}
                subHeader={t('system.hardwareDetails.copy')}
                route={pages.HARDWARE_INFO}
                navState={{ system }}
                iconElement={<GearIcon />}
              />
            </OmnidianHardwareWrapper>
          </SystemSideCards>
        </SystemPageBodyWrapper>
      )}
    </SystemPageWrapper>
  );
}

export default SystemPage;
