import {
  authenticatedKey,
  startSurflyKey,
  errorSurflyKey,
  PER_SESSION_STORAGE_KEYS,
} from 'constants/localStorageKeys';

export const removeLocalStoragePerSessionItems = () => {
  PER_SESSION_STORAGE_KEYS.forEach((key) => localStorage.removeItem(key));
};

export const removeLocalStorageItems = () => {
  localStorage.removeItem(authenticatedKey);
  localStorage.removeItem('impersonator');
  localStorage.removeItem(startSurflyKey);
  localStorage.removeItem(errorSurflyKey);

  removeLocalStoragePerSessionItems();
};

export default removeLocalStorageItems;
