import React from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'shared/components/Button/Button';
import { PinInput } from 'shared/components/PinInput/PinInput';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';
import {
  BodyText,
  RegistrationEmailFormStyled,
} from '../RegistrationEmailForm/RegistrationEmailForm.styles';
import { LoadingWrapper } from './VerificationPinForm.styles';

export interface VerificationPinFormInput {
  pin: string;
}

type Props = {
  email: string;
  timeLeftSeconds: number;
  verifying: boolean;
  isMFA: boolean;
  isNewCode: boolean;
  pinError?: string;
  onSubmitForm: SubmitHandler<VerificationPinFormInput>;
  onResendPin: () => void;
};

export function VerificationPinForm({
  email,
  timeLeftSeconds,
  verifying,
  isMFA,
  isNewCode,
  pinError: pinErrorProp,
  onSubmitForm,
  onResendPin,
}: Props) {
  const codeExpired = timeLeftSeconds <= 0;

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = useForm<VerificationPinFormInput>();

  const pinError = pinErrorProp || errors.pin?.message;
  const watchPin = useWatch({
    control,
    name: 'pin',
  });

  const handlePinEntered = (pin: string) => {
    setValue('pin', pin);
  };

  const handleSubmitForm = async (formInput: VerificationPinFormInput) => {
    if (!watchPin) {
      setError('pin', { message: t('verification.noCode') });
      return;
    }

    if (watchPin.length < 6) {
      setError('pin', { message: t('verification.wrongCode') });
      return;
    }

    onSubmitForm(formInput);
  };

  const handlePinChange = (pin: string) => {
    setValue('pin', pin);
    clearErrors('pin');
  };

  const handleResendPin = () => {
    clearErrors('pin');
    onResendPin();
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const bodyText = isMFA
    ? t(isNewCode ? 'verification.newCodeSent' : 'verification.confirm', { email })
    : t('verification.weSentALinkTo', { email });

  return (
    <RegistrationEmailFormStyled onSubmit={handleSubmit(handleSubmitForm)}>
      <div>
        {isMFA && !isNewCode && (
          <>
            <BodyText as="p">{t('verification.newDevice')}</BodyText>
            <br />
          </>
        )}
        <Trans>
          <BodyText as="p">{bodyText}</BodyText>
        </Trans>
        <BodyText $margin="18px 0 0" as="p">
          {t('verification.enter6DigitCode', { email })}
        </BodyText>
      </div>

      <PinInput
        onPinEntered={handlePinEntered}
        onChange={handlePinChange}
        hasError={Boolean(pinError)}
        errorMessage={pinError}
      />

      <BodyText $margin="18px 0 0" as="p">
        <Trans>
          {codeExpired
            ? t('verification.codeHasExpired')
            : t('verification.codeWillExpireIn', { time: formatTime(timeLeftSeconds) })}
        </Trans>
      </BodyText>

      {verifying && (
        <LoadingWrapper>
          <LoadingLogo />
          <BodyText as="p">{t('verification.verifying')}</BodyText>
        </LoadingWrapper>
      )}

      <div>
        <Button margin="24px 0 0" type="submit" label={t('verification.verify')} isFullWidth />
        <Button
          styleVariant="tertiary"
          margin="12px 0 0"
          label={t('verification.resendCode')}
          isFullWidth
          onClick={handleResendPin}
        />
      </div>
    </RegistrationEmailFormStyled>
  );
}

export default VerificationPinForm;
