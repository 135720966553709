import styled from 'styled-components';
import { breakpoints } from 'shared/design-system/theme/breakpoints';
import { FONT_FAMILY } from '../Text/Text';
import { ButtonStyledProps } from '../Button/Button.styles';

export const FallbackBody = styled.div`
  display: flex;
  flex-direction: column;
`;

/* 
    TODO: implement dynamic theming for below components –> disabled 
    for now because fallback does not have access to the theme provider
  */
export const FallbackWrapper = styled.div`
  font-family: ${FONT_FAMILY};
  color: #22345b;
  background: #fff;
  display: flex;
  width: 100%;
  max-width: 850px;
  padding: 100px;
  padding-left: 0;
  margin: auto;
  justify-content: space-between;

  button {
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
  }

  ${FallbackBody} {
    width: 385px;
  }

  h1 {
    font-size: 34px;
    line-height: 44px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #464646;
    font-weight: 300;
  }
  p:nth-of-type(2) {
    margin-top: 0px;
  }

  svg {
    height: 384px;
  }

  @media screen and (width <= ${breakpoints.medium}) {
    flex-direction: column-reverse;
    padding: 100px 0 126px;
    align-items: center;

    ${FallbackBody} {
      width: 309px;
    }

    h1 {
      font-size: 24px;
      line-height: 36px;
    }

    p {
    }

    svg {
      height: 284px;
    }

    button {
      margin-top: 32px;
    }
  }

  @media screen and (width <= ${breakpoints.small}) {
    padding: 27px 0 48px;

    ${FallbackBody} {
      width: 280px;
    }

    svg {
      height: 177px;
    }

    button {
      margin-top: 24px;
    }
  }
`;

export const ErrorButtonStyled = styled.button<Partial<ButtonStyledProps>>`
  ${({ $margin, $maxWidth, $styleVariant }) => `
    display: flex;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 8px;
    color: #fff;
    background: #006fc8;
    border: 1.5px solid #006fc8;
    border-radius: 4px;
    margin: ${$margin};
    font-family: ${FONT_FAMILY};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    height: 44px;
    width: fit-content;
    min-width: ${$styleVariant === 'tertiary' ? '40px' : '100px'};
    max-width: ${$maxWidth || 'none'};
    &:hover {
      color: #fff;
      background: #0257a4;
      border: #0257a4;
    }
    &:active {
      color: #fff;
      background: #22345b;
      border: #22345b;
    }
    svg {
      transform: translateY(-2px); // Avenir font seems to have a weird vertical alignment
      height: auto; 
    }
    `}
`;
