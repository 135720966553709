import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import useUser from 'hooks/useUser';
import { perSessionAuthCopyAckModal } from 'constants/localStorageKeys';
import {
  MonitoringQueryKey,
  prefetchMonitoringQueries,
} from 'modules/system/utils/prefetchQueries';
import { AuthCopyAckModal } from 'shared/components/AuthCopyAckModal/AuthCopyAckModal';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { MilestoneType } from 'modules/types';
import { OverviewHeader } from '../components/OverviewHeader/OverviewHeader';
import { ManageSection } from '../components/ManageSection/ManageSection';

import { Container, SystemWrapper } from './Overview.styles';
import { HouseGraphic } from '../components/HouseGraphic/HouseGraphic';
import { SystemCard } from '../components/SystemCard/SystemCard';

export function Overview() {
  const { userResult, selectedAccount, setSelectedAccount } = useUser();
  const user = userResult?.user;
  const queryClient = useQueryClient();

  const agreements = (selectedAccount?.agreements || []).filter(
    (agreement) =>
      (agreement.current_milestone === MilestoneType.IN_SERVICE ||
        agreement.current_milestone === null) &&
      agreement.archived === false,
  );
  const agreement = agreements.length > 0 ? agreements[0] : null;

  // get system with monitoring enabled
  const { system } = getMonitoringSystemInfo(selectedAccount);
  const hasBattery = Boolean(system?.batteries?.length);

  useEffect(() => {
    async function prefetchQueries() {
      if (system) await prefetchMonitoringQueries({ queryClient, system });
    }

    // run the monitoring query prefetch group if there's no timezone req already in the query cache
    if (!queryClient.getQueryData([MonitoringQueryKey.TIMEZONE, system?.id])) {
      prefetchQueries();
    }
  }, [queryClient, system]);

  const handleAccountChange = (accountId: string) => {
    if (accountId !== selectedAccount?.id) {
      const newAccount = user?.accounts.find((account) => account.id === accountId);
      setSelectedAccount(newAccount);
    }
  };

  return (
    <>
      <Container>
        {user && selectedAccount && (
          <OverviewHeader
            selectedAccount={selectedAccount}
            accounts={user.accounts}
            userAddress={user.address}
            onAccountChange={handleAccountChange}
          />
        )}
        <SystemWrapper>
          <HouseGraphic hasBattery={hasBattery} />
          <SystemCard system={system} />
        </SystemWrapper>
        <ManageSection agreement={agreement} />
      </Container>
      <AuthCopyAckModal account={selectedAccount} modalKey={perSessionAuthCopyAckModal} />
    </>
  );
}

export default Overview;
